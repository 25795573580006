<script>
import { Card, InfoSection } from "@/components";

import Rellax from "rellax";

export default {
    name: "Presentation",
    bodyClass: "presentation-page",
    components: {
        Card,
        InfoSection,
    },
    mounted() {
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                var rellax = new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);

            var rellaxHeader = new Rellax(".rellax-header");
            var rellaxText = new Rellax(".rellax-text");
        }
    },
};
</script>

<template>
    <div class="wrapper presentation-page">
        <div class="page-header clear-filter">
            <div class="rellax-header rellax-header-sky">
                <parallax
                    class="page-header-image"
                    style="background-image: url('img/bgs/marina.jpg')"
                />
            </div>
            <div
                class="rellax-header rellax-header-buildings"
                data-rellax-speed="0"
            >
                <parallax
                    class="page-header-image page-header-city"
                    style="background-image: url('img/bgs/bridge.png')"
                />
            </div>
            <div class="rellax-text-container rellax-text">
                <h1 class="h1-seo" data-rellax-speed="3">La Marina</h1>
                <div class="pro">del Pinet</div>
            </div>
            <h3 class="h3-description rellax-text" data-rellax-speed="-1">
                {{$t('frontpage.cure_your_soul_enhance_your_senses')}}
            </h3>
            <h6
                class="category category-absolute rellax-text"
                data-rellax-speed="-2"
            >
                <parallax>
                    {{$t('frontpage.disclaimer')}}
                </parallax>
            </h6>
        </div>
    </div>
</template>


<style scoped lang="scss">
.github-buttons {
    display: flex;
    align-content: center;
}
</style>
